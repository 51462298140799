@font-face {
  font-family: 'TT_TANK';
  src: url('../fonts/TT_TANK_Medium.woff2') format('woff2'),
  url('../fonts/TT_TANK_Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT_TANK';
  src: url('../fonts/TT_TANK_Bold.woff2') format('woff2'),
  url('../fonts/TT_TANK_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT_TANK';
  src: url('../fonts/TT_TANK_Light.woff2') format('woff2'),
  url('../fonts/TT_TANK_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


body {
  font-family: 'TT_TANK';
  padding: 0;
  margin: 0;
  color: #FFFFFF;
  background: #252C2C;

  section{
    &#register-content{
      position: relative;
      margin: 64px auto;
      width: calc(100% - 200px);
      max-width: 1242px;
      font-weight: 300;
      font-size: 16px;
      line-height: 144%;
      letter-spacing: 0.05em;
      @media screen and (max-width:1100px){
        width: calc(100% - 60px);
      }
      @media screen and (max-width:760px){
        width: calc(100% - 32px);
        margin-top: 20px;
      }
      h1{
        padding: 0;
        margin: 0;
        margin-bottom: 35px;
        font-weight: 700;
        font-size: 42px;
        line-height: 46px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        @media screen and (max-width:760px){
          font-size: 26px;
          line-height: 34px;
        }
      }

      #tank-logo{
        position: relative;
        display: none;
        text-align: center;
        margin-bottom: 50px;
        @media screen and (max-width:760px){
          display: block;
        }
      }
      #top-image{
        width: 100%;
        margin-bottom: 87px;
        @media screen and (max-width:1100px){
          margin-bottom: 50px;
        }
        @media screen and (max-width:760px){
          display: none;
        }
      }
      #top-m-image{
        width: 100%;
        display: none;
        margin-bottom: 50px;
        @media screen and (max-width:760px){
          display: block;
        }
      }
    }

    .footer-text{
      font-weight: 300;
      font-size: 16px;
      line-height: 144%;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
    .register{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 60px auto 47px;
      width: 100%;
      &.without-calendar{
        justify-content: center;
        @media screen and (max-width:760px){
          display: block;
          padding: 0;
          margin: 60px -16px 47px;
          box-sizing: border-box;
        }
        #form-layer{
          width: 710px;
          box-sizing: border-box;
          padding-left: 112px;
          @media screen and (max-width:760px){
            width: 100%;
            padding: 30px 16px;
            margin: 0;
            box-sizing: initial;
          }
          &:before{
            display: none;
          }
        }
      }
      @media screen and (max-width:760px){
        flex-direction: column-reverse;
      }

      #form-layer{
        position: relative;
        display: block;
        width: 50%;
        background: #fff;
        padding-top: 35px;
        color: #000000;
        padding-bottom: 31px;
        @media screen and (max-width:760px){
          width: 100%;
          padding: 30px 16px;
          margin: 0 -16px;
        }
        &:before{
          position: absolute;
          display: block;
          content: "";
          width: 100vw;
          top: 0;
          left:-100vw;
          background: #fff;
          height: 100%;
        }
        .form-title{
          position: relative;
          display: block;
          font-weight: 700;
          font-size: 42px;
          line-height: 46px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          @media screen and (max-width:760px){
            font-size: 28px;
            line-height: 33px;
          }
        }
        .input-field{
          position: relative;
          display: block;
          width: 90%;
          max-width: 423px;
          border-bottom: #000 solid 1px;
          input{
            position: relative;
            display: block;
            outline: none;
            border: 0;
            line-height: 24px;
            padding-top: 36px;
            background: #fff;
            font-weight: 300;
            font-size: 12px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #000000;
            @media screen and (max-width:760px){
              font-size: 11px;
            }
          }
          .info-layer{
            position: absolute;
            display: block;
            right: 0;
            top: calc(50% - 7px);
            z-index: 1;
            &:hover{
              .info-layer-data{
                display: block;
              }
            }
            .info-layer-data{
              position: absolute;
              display: none;
              pointer-events: none;
              white-space: nowrap;
              left: 17px;
              top: -37px;
              background: rgba(13, 13, 13, 0.8);
              opacity: 0.9;
              padding: 14px 20px;
              font-weight: 300;
              font-size: 12px;
              line-height: 144%;
              letter-spacing: 0.05em;
              color: #FFFFFF;
              @media screen and (max-width:760px){
                left: auto;
                right: -20px;
              }
            }
          }
        }
        small{
          font-weight: 300;
          font-size: 12px;
          line-height: 135%;
          letter-spacing: 0.05em;
          color: #000000;
          opacity: 0.5;
        }
        button{
          position: relative;
          display: block;
          border: none;
          outline: none;
          cursor: pointer;
          background: #000;
          width: 206px;
          height: 53px;
          line-height: 53px;
          text-align: center;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          color: #FFFFFF;
          margin-top: 18px;
          margin-bottom: 9px;
          @media screen and (max-width:760px){
            margin-top: 27px;
          }
        }
        .form-checkbox{
          position: relative;
          display: block;
          margin-top: 14px;
          padding-left: 20px;
          width: 90%;
          max-width: 423px;
          font-style: normal;
          font-weight: 300;
          font-size: 10px;
          line-height: 120%;
          letter-spacing: -0.015em;
          color: #000000;
          @media screen and (max-width:760px){
            margin-top: 27px;
          }
          label{
            cursor: pointer;
            a{
              text-decoration: none;
              color: #FF9549;
              border-bottom: #FF9549 solid 1px;
            }
          }

          input{
            display: none;
          }
          &:before{
            position: absolute;
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            left: 0.34px;
            top: 0px;
            background: #FFFFFF;
            border: 1px solid #FF9549;
            border-radius: 2px;
          }

          &.checked{
            &:before{
              background: #FF9549 url(../img/check.svg) no-repeat 50% 50%;
            }
          }
        }
      }

      #calendar{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 50%;
        background: #2E3535;
        @media screen and (max-width:760px){
          width: 100%;
          padding: 16px;
          margin: 0 -16px;
          overflow-x: hidden;
        }
        #calendar-data{
          position: relative;
          display: block;
          width: calc(100% - 32px);
          max-width: 400px;
          text-align: center;
          color: #fff;
          @media screen and (max-width:760px){
            width: 100%;
          }
          .calendar-month{
            position: relative;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.01em;
            font-variant: small-caps;
          }
          .calendar-year{
            position: relative;
            display: block;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #FF9549;
            margin-bottom: 6px;
          }
          .calendar-format-title{
            position: relative;
            display: block;
            font-weight: 300;
            font-size: 16px;
            line-height: 144%;
            letter-spacing: 0.05em;
            margin-bottom: 4px;
          }
          .calendar-format-radio{
            position: relative;
            display: flex;
            justify-content: center;
            gap: 40px;
            margin-bottom: 20px;
            .calendar-format{
              position: relative;
              display: block;
              font-weight: 300;
              font-size: 16px;
              line-height: 144%;
              letter-spacing: 0.05em;
              padding-left: 17px;
              cursor: pointer;
              &:before{
                position: absolute;
                display: block;
                content: "";
                width: 12px;
                height: 12px;
                border-radius: 6px;
                border: #fff solid 1px;
                box-sizing: border-box;
                left: 0;
                top: 5px;
              }
              &.active{
                &:after{
                  position: absolute;
                  display: block;
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 6px;
                  background: #FF9549;
                  left: 3px;
                  top: 8px;
                }
              }
            }
          }
          .calendar-week{
            position: relative;
            display: flex;
            justify-content: space-around;
            width: 100%;
            padding-top: 8px;
            border-top :#fff solid 1px;
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            margin-bottom: 12px;
          }
          .calendar-days{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            justify-content: space-between;
            .calendar-day{
              width: 14.28%;
              height: 50px;
              border: #fff solid 1px;
              border-right: 0;
              box-sizing: border-box;
              line-height: 50px;
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              text-align: center;
              margin-bottom: -1px;
              background: rgba(13, 13, 13, 0.6);
              @media screen and (max-width:760px){
                font-size: 16px;
                height: 36px;
                line-height: 36px;
              }
              &.empty{
                background: none;
              }
              &:nth-child(7n){
                border-right: #fff solid 1px;
              }
              &.available{
                background: #FF9549;
                cursor: pointer;
                &.active{
                  background: #fff;
                  color: #000000;
                }
              }
            }
          }
          .calendar-choise-time{
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #FF9549;
          }

          #select-time-block{
            position: absolute;
            display: none;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            padding: 20px;
            height: auto;
            left: 50%;
            top: 50%;
            background: rgba(13, 13, 13, 0.75);
            &.active{
              display: flex;
            }
            .time-item{
              position: relative;
              display: block;
              width: 73px;
              height: 23px;
              border: #fff solid 1px;
              box-sizing: border-box;
              text-align: center;
              color: #fff;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              cursor: pointer;
              &:hover{
                background: #fff;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}
